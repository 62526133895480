import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateValidationError } from '@mui/x-date-pickers/models';
import Palette from '../../../themes/palette';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import styled from '@emotion/styled';
import { PATPalette } from '../../../themes/palette';

export interface DatePickerProps {
    type?: 'single' | 'range';
    onChange?: any;
    onBlur?: any;
    value?: Dayjs | null;
    labelStart?: string;
    labelEnd?: string;
    startDate: string;
    endDate?: string;
    timeselection?: boolean;
    enabled?: boolean;
    hidden?: boolean;
    isMmYyyy?: boolean;
    style?: string;
    selectedSdate?: string;
    selectedEdate?: Dayjs;
    slotProps?: Object;
    minErrorMsg?: string;
    maxErrorMsg?: string;
    isYyyy?: boolean;
    customOpenIcon?: React.ReactNode;
    isMm?: boolean;
}

const DateWrapper = styled('div')(() => ({
    display: 'flex',
    borderRadius: '50%',
    aspectRatio: '1',
    backgroundColor: Palette.primary.extraLight,
    width: '32px',
    height: '32px',
    padding: '8px',
    justifyContent: 'center',
    alignItems: 'center'
}));

const StyledBasicDatePicker = styled(DatePicker)(() => ({
    '& .MuiIconButton-root': {
        borderRadius: '50%',
        padding: '4px',
        background: PATPalette.secondary.skyBlue[100],
        '& svg': {
            width: '16px',
            height: '16px',
            color: PATPalette.secondary.skyBlue[400]
        }
    },
    '& .MuiDayCalendar-root': {
        '& .MuiPickersDay-root': {
            '& .MuiPickersDay-dayWithMargin': {
                '& .Mui-selected, :hover, :focus': {
                    backgroundColor: PATPalette.primary.skyBlue
                }
            }
        }
    },
    '& .MuiMonthCalendar-root': {
        '& .MuiPickersMonth-root': {
            '& .MuiPickersMonth-monthButton': {
                '& .Mui-selected, :hover, :focus': {
                    backgroundColor: PATPalette.primary.skyBlue
                }
            }
        }
    },
    '& .MuiYearCalendar-root': {
        '& .MuiPickersYear-root': {
            '& .MuiPickersYear-yearButton': {
                '& .Mui-selected, :hover, :focus': {
                    backgroundColor: PATPalette.primary.skyBlue
                }
            }
        }
    }
}));

export const BasicDatePicker = ({
    type = 'single',
    labelStart,
    labelEnd,
    hidden,
    enabled,
    startDate,
    endDate,
    timeselection,
    isMmYyyy,
    isYyyy,
    isMm,
    onChange,
    onBlur,
    value,
    slotProps,
    minErrorMsg,
    maxErrorMsg,
    customOpenIcon = false
}: DatePickerProps) => {
    const [selectedStartDate] = useState<Dayjs | null>(dayjs(''));
    const [error, setError] = React.useState<DateValidationError | null>(null);
    const components = customOpenIcon
        ? {
              OpenPickerIcon: () => (
                  <DateWrapper>
                      <CalendarMonthIcon sx={{ color: Palette.primary.main }} />
                  </DateWrapper>
              )
          }
        : {};

    const errorMessage = React.useMemo(() => {
        switch (error) {
            case 'maxDate': {
                return maxErrorMsg;
            }

            case 'minDate': {
                return minErrorMsg;
            }

            default: {
                return '';
            }
        }
    }, [error]);

    const handleChange = (value: any) => {
        if (onChange) onChange(value);
    };

    const getPlaceholderOrFormat = () => (isMmYyyy ? 'MM/YYYY' : isYyyy ? 'YYYY' : isMm ? 'MMM' : 'MM/DD/YYYY');

    return (
        <>
            {!hidden && (
                <MuiLocalizationProvider dateAdapter={AdapterDayjs}>
                    {timeselection ? (
                        <DateTimePicker
                            label={labelStart}
                            views={
                                isMmYyyy
                                    ? ['year', 'month', 'day', 'hours', 'minutes', 'seconds']
                                    : isYyyy
                                    ? ['year']
                                    : isMm
                                    ? ['month']
                                    : ['year', 'month', 'day', 'hours', 'minutes', 'seconds']
                            }
                            disabled={!enabled}
                            minDateTime={dayjs(startDate)}
                            maxDateTime={dayjs(endDate)}
                            onChange={handleChange}
                            value={dayjs(value)}
                            slotProps={slotProps}
                            open
                            slots={components}
                            format={getPlaceholderOrFormat()}
                        />
                    ) : (
                        <StyledBasicDatePicker
                            label={labelStart}
                            views={
                                isMmYyyy
                                    ? ['year', 'month']
                                    : isYyyy
                                    ? ['year']
                                    : isMm
                                    ? ['month']
                                    : ['year', 'month', 'day']
                            }
                            disabled={!enabled}
                            minDate={dayjs(startDate)}
                            maxDate={endDate ? dayjs(endDate) : undefined}
                            onChange={handleChange}
                            value={value === null ? null : dayjs(value)}
                            format={getPlaceholderOrFormat()}
                            onError={(newError) => setError(newError)}
                            slotProps={{
                                textField: {
                                    helperText: errorMessage,
                                    onBlur,
                                    placeholder: getPlaceholderOrFormat()
                                },
                                calendarHeader: { sx: isMm ? { div: { marginRight: '-18px' } } : {} },
                                ...slotProps
                            }}
                            slots={components}
                            openTo={isMm ? 'month' : 'year'}
                        />
                    )}
                </MuiLocalizationProvider>
            )}

            {!hidden && type != 'single' && (
                <MuiLocalizationProvider dateAdapter={AdapterDayjs}>
                    {timeselection ? (
                        <DateTimePicker
                            label={labelEnd}
                            views={
                                isMmYyyy
                                    ? ['year', 'month', 'hours', 'minutes', 'seconds']
                                    : ['year', 'month', 'day', 'hours', 'minutes', 'seconds']
                            }
                            value={dayjs(endDate)}
                            disabled={!enabled}
                            slotProps={slotProps}
                            minDateTime={dayjs('')}
                            maxDateTime={dayjs(endDate)}
                            onChange={handleChange}
                            format={isMmYyyy ? 'MM/YYYY' : 'MM/DD/YYYY'}
                        />
                    ) : (
                        <DatePicker
                            label={labelEnd}
                            views={isMmYyyy ? ['year', 'month'] : ['year', 'month', 'day']}
                            slotProps={slotProps}
                            disabled={!enabled}
                            minDate={dayjs(selectedStartDate)}
                            maxDate={dayjs(endDate)}
                            onChange={handleChange}
                            value={dayjs(value)}
                            format={isMmYyyy ? 'MM/YYYY' : 'MM/DD/YYYY'}
                        />
                    )}
                </MuiLocalizationProvider>
            )}
        </>
    );
};
